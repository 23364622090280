import React, { useEffect, useState } from "react";
import LocationService from "../../service/LocationService";
import Arrow from '../../assets/right-arrow.webp';

const CityLinks =({subcategory, countryId, stateId, keyword, handleCityClick})=>{
  const [randomCities, setRandomCities] = useState([]);

  useEffect(() => {
    // Check that countryId and stateId are valid before fetching cities
    if (!countryId || !stateId) return;

    let isMounted = true; // Used to prevent updating state if component unmounts

    const fetchAndSetRandomCities = async () => {
      try {
        // Make the API call
        const response = await LocationService.getCityList(countryId, stateId);

        if (isMounted && response && response.length > 0) {
          // Shuffle and slice the cities to get 15 random ones
          const shuffled = response.sort(() => 0.5 - Math.random());
          const selected = shuffled.slice(0, 15);
          setRandomCities(selected);
        }
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchAndSetRandomCities();

    // Cleanup function to prevent memory leaks if the component unmounts
    return () => {
      isMounted = false;
    };
  }, [countryId, stateId]); // Only trigger API call when countryId or stateId changes

    return(<>
      <ul>
        {randomCities.map((city, index) => (
          <li key={index}>
          <a onClick={() => handleCityClick(city)} role="button"><img src={Arrow} className='arrow_link' alt='Arrow Icon'/>{subcategory} {keyword} {city.name}</a>
           </li>
        ))}
      </ul>
    </>
    );

};

export default CityLinks;