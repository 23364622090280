import React from 'react';
import logo from '../../assets/logo.webp';
import { Navbar, Nav, Container } from 'react-bootstrap';
import appStore from '../../assets/appstore.webp';
import googlePlay from '../../assets/googleplay.webp';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  return (
    <Navbar bg="white" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/home">
          <img className='logo'
            src={logo}
            alt="Breakdown Inc"
          // Adjust size as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto cust-nav align-items-center">
            <Nav.Link href="/home" className={location.pathname === '/home' ? 'active' : ''}>Home</Nav.Link>
            <Nav.Link href="/plans" className={location.pathname === '/plans' ? 'active' : ''}>plans</Nav.Link>
            {/* <Nav.Link href="/about" className={location.pathname === '/about' ? 'active' : ''}>About</Nav.Link> */}
            <Nav.Link href="/contact" className={location.pathname === '/contact' ? 'active' : ''}>Contact</Nav.Link>
            <Nav.Link href="/blogs" className={location.pathname === '/blogs' ? 'active' : ''}>Blog</Nav.Link>
            <Nav.Link className='img_btn' target='blank' href="https://apps.apple.com/us/app/breakdown-inc/id6739872583"><img src={appStore} className='img-fluid' alt='App Store'/></Nav.Link>
            <Nav.Link className='img_btn' target='blank' href="https://play.google.com/store/apps/details?id=com.breakdowninc.com&hl=en"><img src={googlePlay} className='img-fluid' alt='Play Store'/></Nav.Link>
            {/* <Nav.Link className='app_btn' href="/app"><i className="fa fa-mobile-alt" title="mobile"></i> &nbsp;Download App</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
